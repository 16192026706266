export const revisedSiteCopy = {
  landingPage: {
    heading: {
      mobile: (
        <>
          {`Welcome To `}
          <span>{`The Future `}</span>
          <span>{`of Fandom`}</span>
        </>
      ),
      desktop: (
        <>
          {`Welcome To The `}
          <span>{`Future of Fandom`}</span>
        </>
      ),
    },
    subheading: {
      mobile: (
        <>
          <span>{`We foster artist-fan bonds with bundle sales, offering `}</span>
          <span>{`exclusive content, direct artist connections, and unique `}</span>
          <span>{`experiences through gaming and live events. Explore with us!`}</span>

          <span>
            {`Are you an artist, subscribe now `}
            <span>{`and get early access`}</span>
          </span>
        </>
      ),
      desktop: (
        <>
          <span>{`We foster artist-fan bonds with bundle sales, offering exclusive content, direct artist `}</span>
          <span>{`connections, and unique experiences through gaming and live events. Explore with us!`}</span>

          <span>{`Are you an artist, Subscribe now and get early access`}</span>
        </>
      ),
    },
    cta: `Are you a fan, download here`,
    successText: `Success! You'll be the first to know when we launch.`,
    placeholder: "Email Address",
    buttonName: "Subscribe",
  },
};
