import React, { useState, FunctionComponent } from "react";
import axios from "axios";
import {
  EmailButton,
  Form,
  Input,
  Error,
  InputContainer,
  Success,
} from "./styled";
import { useForm } from "react-hook-form";
interface EmailSignupFormProps {
  buttonName: string;
  placeholder: string;
  successText: string;
}
interface FormInputProps {
  email: string;
}

const EmailSignupForm: FunctionComponent<EmailSignupFormProps> = ({
  buttonName,
  successText,
  placeholder,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputProps>();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data: FormInputProps) => {
    try {
      const response = await axios.post("/api/subscribe", {
        email: data.email,
      });
      console.log("Subscribed:", response.data);
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error("Subscription error:", error);
      setIsSubmitted(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputContainer>
        <Input
          type="text"
          placeholder={placeholder}
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Does not match email format",
            },
            onChange: () => setIsSubmitted(false),
          })}
        />
        <Error>{errors.email?.message}</Error>
        {isSubmitted && <Success>{successText}</Success>}
      </InputContainer>
      <EmailButton type="submit">{buttonName}</EmailButton>
    </Form>
  );
};

export default EmailSignupForm;
