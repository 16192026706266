import Link from "next/link";
import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  height: 100px;
  width: 100vw;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`;

export const FooterLink = styled(Link)``;


export const LinkText = styled.span`
  color: gray;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 70%;
  letter-spacing: -0.4px;
  pointer-events: auto;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled(LinkText)`
  &:hover {
    text-decoration: none;
  }
`;
