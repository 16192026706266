import styled from "styled-components";

export const NavBarWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  left: 0;
  top: 0;
  overflow: hidden;
`;

export const NavBarContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  position: relative;
  padding: 40px 150px;
  z-index: 100;
  pointer-events: none;
  @media (max-width: 825px) {
    padding: 40px 22px;
  }
`;

export const NavBarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  & span:first-of-type {
    width: 130px !important;
  }
  img {
    object-fit: contain;
  }

  @media (max-width: 825px) {
    & span:first-of-type {
      width: 97.3px !important;
    }
  }
`;

export const Contact = styled.span`
  color: black;
  padding-left: 18px;
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 70%;
  letter-spacing: -0.4px;
  pointer-events: auto;
  text-transform: uppercase;
  background: white;
  width: auto;
  border-radius: 10px;
  padding: 15px 20px;
  cursor: pointer;
  margin-left: auto;
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background-color: #dbfe04;
  }
  @media (max-width: 825px) {
    font-size: 13px;
    border-radius: 5px;
    padding: 9px 13px;
  }
`;
