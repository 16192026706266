import styled from "styled-components";
import Link from "next/link";

export const LandingContainer = styled.div`
  width: 100%;
  min-height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  zindex: 20;
  top: 0;
  left: 0;
  text-align: center;
  color: white;
  overflow: auto;
  background-image: url("images/revisedBgDesktop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 150px 0 0 0;

  & span {
    display: block;
  }

  @media (max-width: 825px) {
    padding: 135px 0 0 0;
    justify-content: start;
    background-image: url("images/mobileBg.png");
  }
`;

export const Content = styled.div`
  margin-bottom: auto;
  @media (max-width: 825px) {
    margin-bottom: 100px;
  }
`;

export const Heading = styled.h4`
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 95px;
  font-weight: 500;
  line-height: 83%;
  letter-spacing: -4.75px;
  text-transform: uppercase;
  padding: 0 20px;
  @media (max-width: 825px) {
    font-size: 40px;
    line-height: 38px;
    width: 100%;
    letter-spacing: -2px;
    padding: 0 0;
  }
`;

export const SubHeading = styled.h6`
  color: #adadb5;
  padding: 40px 0 60px 0;
  font-family: "Inter";
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.408px;

  & span {
    display: block;
  }

  & span:last-of-type {
    margin-top: 28px;
    text-transform: uppercase;
    font-weight: 500;
    color: #dbfe04;
  }

  @media (max-width: 825px) {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.5px;
    padding: 30px 0 30px 0;
    & span:nth-of-type(3) {
      margin-bottom: 16px;
    }
    & span:last-of-type {
      margin-top: 0px;
      text-transform: uppercase;
      font-weight: 500;
      color: #dbfe04;
    }
  }

  @media (max-width: 365px) {
    font-size: clamp(8px, 3.2vw, 12px);
  }
`;

export const AppStore = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: column;
  gap: 30px;
  color: #fff;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  padding-top: 40px;
  @media (max-width: 825px) {
    font-size: 12px;
    gap: 18px;
    line-height: 12px;
    padding-top: 0;
    margin-bottom: auto;
    margin-top: 0;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 825px) {
    gap: 13px;
  }
`;

export const StoreLink = styled(Link)``;

export const LinkDiv = styled.div`
  border: none;
  border-radius: 10px;
  background: white;
  padding: 12px 22px 16px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 825px) {
    border-radius: 8px;
    padding: 2px 15px 4px 11px;
  }
`;

export const LinkText = styled.p`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: flex-start;
  color: black;
  font-family: "Inter";
  font-size: 12.8px;
  font-weight: 500;
  line-height: 150%;
  & span {
    display: block;
    font-size: 24px;
    letter-spacing: -1.5px;
  }
  @media (max-width: 825px) {
    font-size: 9px;
    & span {
      font-size: 18px;
      letter-spacing: -1.5px;
    }
  }
`;
