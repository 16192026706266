import type { NextPage } from "next";

import PageContainer from "components/PageContainer";
import RevisedSite from "containers/RevisedSite";

// use this if we want to revert to our old site design
// import LegacySite from "containers/LegacySite";

const Home: NextPage = () => {
  return (
    <>
      <RevisedSite />
    </>
  );
};

export default Home;
