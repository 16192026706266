import { FunctionComponent, useEffect, useState } from "react";
import {
  LandingContainer,
  Heading,
  SubHeading,
  Content,
  AppStore,
  LinkContainer,
  StoreLink,
  LinkText,
  LinkDiv,
} from "./styled";
import NavBar from "components/NavBar/navbar";
import EmailSignupForm from "components/Form";
import { revisedSiteCopy } from "components/Copy/revisedCopyObject";
import Image from "next/image";
import Footer from "./Footer";

const RevisedSite: FunctionComponent = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 825);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <NavBar />
      <LandingContainer>
        <Content>
          <Heading>
            {isMobileView
              ? revisedSiteCopy.landingPage.heading.mobile
              : revisedSiteCopy.landingPage.heading.desktop}
          </Heading>
          <SubHeading>
            {isMobileView
              ? revisedSiteCopy.landingPage.subheading.mobile
              : revisedSiteCopy.landingPage.subheading.desktop}
          </SubHeading>
          <EmailSignupForm
            successText={revisedSiteCopy.landingPage.successText}
            placeholder={revisedSiteCopy.landingPage.placeholder}
            buttonName={revisedSiteCopy.landingPage.buttonName}
          />
        </Content>
        <AppStore>
          <p style={{ textTransform: "uppercase" }}>
            {revisedSiteCopy.landingPage.cta}
          </p>
          <LinkContainer>
            <StoreLink
              passHref
              href="https://play.google.com/store/apps/details?id=studio.biom.app&pli=1"
            >
              <a target="_blank">
                <LinkDiv>
                  <Image
                    alt="google store"
                    src="/images/googleStore.svg"
                    width={isMobileView ? "20px" : "32px"}
                    height="40px"
                  />
                  <LinkText>
                    Get it on <span>Google Play</span>
                  </LinkText>
                </LinkDiv>
              </a>
            </StoreLink>
            <StoreLink
              passHref
              href="https://apps.apple.com/us/app/biom-celebrating-fandom/id6472498554?uo=2"
            >
              <a target="_blank">
                <LinkDiv>
                  <Image
                    alt="apple store"
                    src="/images/appleStore.svg"
                    width={isMobileView ? "20px" : "32px"}
                    height="40px"
                  />
                  <LinkText>
                    Download on the <span>App Store</span>
                  </LinkText>
                </LinkDiv>
              </a>
            </StoreLink>
          </LinkContainer>
        </AppStore>

  
        <Footer/>

      </LandingContainer>
    </>
  );
};

export default RevisedSite;
