import { FooterContainer, FooterLink, LinkText, Text } from "./styled"
import { FunctionComponent } from "react"
// import Logo from "./logo";

const Footer: FunctionComponent = () => {
    return (
        <FooterContainer>
            <FooterLink passHref href='/privacy'>
                <a target='_blank'>
                    <LinkText>Privacy Policy</LinkText>
                </a>
            </FooterLink>
            <FooterLink passHref href='/terms'>
                <a target='_blank'>
                    <LinkText>Terms of Service</LinkText>
                </a>
            </FooterLink>
            <Text>© 2024 Biom Studio</Text>
        </FooterContainer>
    )
}

export default Footer
