import { NavBarContent, NavBarHeader, NavBarWrapper, Contact } from "./styled";
import { FunctionComponent } from "react";
// import Logo from "./logo";
import Image from "next/image";
import Link from "next/link";

const NavBar: FunctionComponent = () => {
  return (
    <NavBarWrapper>
      <NavBarContent>
        <NavBarHeader>
          <Image alt="biom logo" layout="fill" src={"/images/logoPng.png"} />
          <Contact>
            <Link href="mailto:info@biom.studio">Contact Us</Link>
          </Contact>
        </NavBarHeader>
      </NavBarContent>
    </NavBarWrapper>
  );
};

export default NavBar;
