import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: auto;
  width: auto;
  @media (max-width: 825px) {
    padding: 0 20px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input`
  padding: 12px 16px;
  border-radius: 14px;
  background: #252528;
  color: white;
  border: none;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.408px;
  width: 380px;

  @media (max-width: 825px) {
    width: 100%;
    font-size: 12px;
    padding: 5.5px 14px;
    border-radius: 9px;
  }
`;

export const EmailButton = styled.button`
  border: none;
  padding: 12px 40px;
  border-radius: 14px;
  font-family: "Space Mono";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;
  text-transform: uppercase;
  color: black;
  font-weight: 400;
  cursor: pointer;
  background-color: #dbfe04;
  pointer-events: auto;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: white;
  }
  @media (max-width: 825px) {
    padding: 9px 18px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.278px;
    border-radius: 9px;
  }
`;

export const Error = styled.p`
  color: red;
  width: 100%;
  position: absolute;
  bottom: -26px;
  left: 0;
  font-family: "Inter";
  pointer-events: none;
  font-size: 13px;
  @media (max-width: 825px) {
    bottom: -20px;
    font-size: 10px;
  }
`;

export const Success = styled.p`
  color: white;
  width: 100%;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -5%;
  position: absolute;
  bottom: -40px;
  left: 0;
  pointer-events: none;
  @media (max-width: 825px) {
    font-size: 10px;
    bottom: -50px;
  }
`;
